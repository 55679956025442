import { useState } from "react";
import { Alert, Button, Loader, Select } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useFetch } from "../../helpers/useFetch";
import { useNavigate } from "react-router";
import { useUser } from "../../providers/UserProvider.jsx";
import { IconAlertCircle } from "@tabler/icons-react";

export default function AreaSelectModal() {
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { user } = useUser();

  const form = useForm({
    initialValues: {
      area: user.area,
    },
  });

  const handleSubmit = async (values) => {
    if (!values.area) return;
    setLoading(true);
    if (user.area !== form.values.area) {
      const res = await useFetch(`/user/change-area`, "PUT", form.values);
      if (res.status === "ok") {
        navigate(0);
      } else {
        setLoading(false);
        console.error(res);
        setError(res.message);
        return;
      }
    }
  };

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        {error && (
          <Alert
            icon={<IconAlertCircle size={16} stroke={1.5} />}
            title="Při změně areálu nastala chyba."
            color="red"
            mb="md"
            mt="lg"
            withCloseButton
            closeButtonLabel="Zavřít upozornění"
            onClose={() => setError(false)}>
            {error}
          </Alert>
        )}
        {fetching ? (
          <div className="flex h-full w-full flex-col items-center justify-center py-8">
            <Loader />
          </div>
        ) : (
          <>
            <Select
              mb="sm"
              label="Areál"
              disabled={loading}
              data={[
                { value: "dvur-kralove", label: "Dvůr Králové", disabled: user.area === "dvur-kralove" },
                { value: "velesin", label: "Velešín", disabled: user.area === "velesin" },
                { value: "velmez", label: "Velké Meziříčí", disabled: user.area === "velmez" },
                { value: "vikend", label: "Víkendy", disabled: user.area === "vikend" },
                { value: "primestky", label: "Příměšťáky", disabled: user.area === "primestky" },
              ]}
              placeholder="Vyber areál..."
              {...form.getInputProps("area")}
            />

            <Button
              fullWidth
              type="submit"
              loading={loading}
              disabled={user.area === form.values.area || !form.values.area}
              mt="md">
              Změnit areál
            </Button>
          </>
        )}
      </form>
    </>
  );
}
